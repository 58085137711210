#Chapter {
    float        : left;

    width        : 100%;
    margin-bottom: 10px;
    .header-img {
        width: 100%;
    }
    .chapterpart {
        position: relative;

        padding : 20px;
        & > p {
            font-size  : 1.3em;
            line-height: 24px;

            position   : relative;
        }
        td {
            @include breakpoint(lg) {
                font-size: 1.2em;
            }
            @include breakpoint(sm) {
                font-size: 1.2em;
            }
            &.question {
                font-weight: 900;

                width      : 26%;
            }
            &.proj {
                font-weight: 900;

                width      : 34%;
            }
            &.under {
                background-color: #ecf9ff;
            }
        }
        .dl-horizontal {
            dt {
                text-align: left;
            }
        }
        & > ul > li,
        & > ol > li,
        & > p > ul > li {
            @include breakpoint(lg) {
                font-size: 1.4em;
            }
            @include breakpoint(sm) {
                font-size: 1.4em;
            }
            &.box {
                list-style-image: url('images/assets/unticked.png');
            }
            &.tick {
                list-style-image: url('images/assets/ticked.png');
            }
        }
        #hvar {
            cursor         : default;
            text-decoration: none;

            color          : black;
        }
    }
}

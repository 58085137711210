.tabs {
    width           : 100%;
    height          : 100%;

    background-color: black;
    .nav {
        position  : relative;

        margin    : 0;
        padding   : 0;

        list-style: none;
        & > li {
            position     : relative;

            float        : left;

            margin       : 0 0 -1px 0;
            padding      : 0;

            border       : none;
            border-radius: 0;
            a {
                color: white;
            }
            & > a {
                display       : block;

                padding       : 10px 20px;

                cursor        : pointer;
                text-transform: uppercase;

                border        : none;
                border-radius : 0;
                outline       : none;
                &:hover {
                    background-color: $menuhover;
                }
            }
            &.active a {
                cursor    : default;

                color     : #333;
                border    : none;
                background: #fff;
            }
            &:last-child {
                position: absolute;
                right   : 0;
            }
            &.dropdown {
                &.active > a {
                    cursor: pointer;
                }
                li > a {
                    cursor: pointer;

                    color : #333;
                    &:hover {
                        background-color: $menuhover;
                    }
                }
            }
            &.menubutt {
                font-size: 2em;
                & > a {
                    margin : 0;
                    padding: 0 .6em;
                }
            }
        }
    }
    .tab-pane {
        height          : 100%;
        padding         : 10px;

        background-color: white;
    }
    .tabs-hide {
        display: none;
    }
}

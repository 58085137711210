#Menu {
    z-index         : 400;

    background-color: $shaded;
    .main-header-img {
        width : 100%;
        margin: 1em auto;
    }
    footer {
        z-index         : 500;

        background-color: $shaded;
        a {
            z-index: 550;
        }
        .img-footer {
            padding: 1em 2em;

            cursor : pointer;
        }
        .vnr {
            font-family: 'Open Sans';
            font-style : light;

            width      : 100%;
            padding    : 1em 2em 0 0;

            text-align : right;

            color      : #ccc;

            @include breakpoint(lg) {
                font-size: 1.4em;
            }
            @include breakpoint(md) {
                font-size: 1.4em;
            }
            @include breakpoint(sm) {
                font-size: 1.4em;
            }
            @include breakpoint(xs) {
                font-size: 1.4em;
            }
        }
    }
    #Menu-tile-container {
        z-index: 405;
        a {
            text-decoration: none;
        }
        &:after {
            z-index: 410;

            display: block;
            clear  : both;

            content: '';
        }
        .menutile {
            z-index: 502;

            cursor : pointer;
            &:hover {
                opacity: .8;
            }
            .tile-img {
                width           : 100%;

                background-color: #fff;
            }
            .tile-title {
                width      : 100%;
                padding    : 1em;

                color      : #dadada;
                text-shadow: 1px 1px #000;

                @include breakpoint(lg) {
                    font-size: .8vw;
                }
                @include breakpoint(md) {
                    font-size: .8vw;
                }
                @include breakpoint(sm) {
                    font-size: 1.2em;
                }
                @include breakpoint(xs) {
                    font-size: .7em;
                }
            }
        }
    }
}

.no-space {
    padding: 0 !important;
}

.shadow {
    @include box-shadow(4px, 4px, 16px, -4px, $shaded);
}

.hyphenate {
    word-wrap      : break-word;
    -webkit-hyphens: auto;
       -moz-hyphens: auto;
            hyphens: auto;

        -ms-hyphens: auto;
    overflow-wrap  : break-word;
}

.flex {
    display  : -webkit-flex;
    display  : -ms-flexbox;
    display  :         flex;

    flex-wrap: wrap;
    img {
        display: flex;
    }
    p {
        width: 60%;

        flex : 1;
    }
}

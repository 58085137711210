.outer {
    position             : absolute;
    top                  : 0;
    bottom               : 0;

    overflow-y           : scroll;

    width                : 100%;

    background-repeat    : no-repeat;
    background-attachment: fixed;
    background-position  : right top;
    background-size      : cover;
}
mark {
    color        : #fff !important;
    border-radius: 2em;
    background   : #cc2229 !important;
}
a {
    position       : relative;

    text-decoration: none;
}
html {
    overflow-y: scroll;
}

li {
    line-height: 30px;
}

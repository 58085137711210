@mixin box-shadow($top, $left, $blur, $color, $inset:"") {
    -webkit-box-shadow:$top $left $blur $color #{$inset};
    -moz-box-shadow:$top $left $blur $color #{$inset};
    box-shadow:$top $left $blur $color #{$inset};
}

@mixin breakpoint($point) {
  @if $point == lg {
    @media (min-width: 1200px) { @content; }
  }
  @else if $point == md {
    @media (min-width: 992px) and (max-width: 1199px)  { @content; }
  }
  @else if $point == sm {
    @media (min-width: 768px) and (max-width: 991px)  { @content; }
  }
  @else if $point == xs {
    @media (max-width: 768px)  { @content; }
  }
}
@mixin print() {
  @media (print) { @content; }
}
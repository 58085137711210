.quiz {
    width: 100%;
    h2 {
        text-align: center;
    }
    .well {
        border-radius: 5px;

        .btn {
            white-space: normal;
        }
        .btn-block {
            white-space: normal;
        }
    }
    .alert {
        margin: 0;
    }
    .quiz-msg {
        margin-bottom: 2em;
        padding: 1em;

        border: 1px solid #eee;
        border-radius: 5px;
        &.bad {
            background-color: pink;
        }
        &.adequate {
            background-color: #feab09;
        }
        &.better {
            background-color: #a3ff3f;
        }
        &.good {
            color: #333;
            background-color: #1cd604;
        }
        h3 {
            font-size: 1.8em;

            text-align: center;
        }
        p {
            font-size: 1.4em;

            text-align: center;
        }
    }
}
